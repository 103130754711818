.navbarTop {
    background: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.35);
}

.logoImage {
    margin-right: 30px;
    display: block;
    max-width: 100%;
    width: 147px;
    height: 40px;
}